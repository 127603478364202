import { debounce } from 'lodash';
import React, { useState, useEffect } from 'react';

interface SizeState {
  width: null | number;
  height: null | number;
}

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<SizeState>({
    width: null,
    height: null,
  });

  useEffect(() => {
    const handleResize = debounce(() => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }, 100);

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};

export const useOnClickOutside = (
  ref: React.MutableRefObject<Node | null>,
  handler: (event?: MouseEvent | TouchEvent) => void
) => {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      if (!ref.current || ref.current.contains(event.target as Node)) {
        return;
      }
      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};

export const useScrollLock = (flag: boolean) => {
  useEffect(() => {
    const rootElement = document.getElementById('root');
    if (flag && rootElement) {
      rootElement.style.overflow = 'hidden';
    } else if (rootElement) {
      rootElement.style.overflow = 'visible';
    }

    return () => {
      if (rootElement) rootElement.style.overflow = 'visible';
    };
  }, [flag]);
};
