import React, { useState } from 'react';

import cx from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';

import styles from './ClipboardCopy.module.scss';

interface ClipboardCopyProps {
  children: React.ReactNode;
  className?: string;
  value?: string | null;
  disabled?: boolean;
}

const fallbackCopyTextToClipboard = (text: string): void => {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.width = '0';
  textArea.style.height = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand('copy');
    const msg = successful ? 'successful' : 'unsuccessful';
    console.log('Fallback: Copying text command was ' + msg);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
};

const copyTextToClipboard = (text: string): void => {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }

  navigator.clipboard.writeText(text).then(
    function () {
      console.log('Async: Copying to clipboard was successful!');
    },
    function (err) {
      console.error('Async: Could not copy text: ', err);
    }
  );
};

const ClipboardCopy: React.FC<ClipboardCopyProps> = ({ children, className, value, disabled = false }) => {
  const [copied, setCopied] = useState(false);

  const handleClick = (): void => {
    if (disabled || !value) return;

    copyTextToClipboard(value);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <button className={cx(styles.ClipboardCopy, { [styles.hintVisible]: copied }, className)} onClick={handleClick}>
      <AnimatePresence>
        {copied && (
          <motion.div
            className={styles.hint}
            variants={{
              hide: {
                opacity: 0,
                transform: 'translate(-50%, -20px)',
              },
              show: {
                opacity: 1,
                transform: 'translate(-50%, 10px)',
              },
            }}
            initial="hide"
            animate="show"
            exit="hide"
            transition={{ ease: 'easeInOut' }}
          >
            Copied to clipboard
          </motion.div>
        )}
      </AnimatePresence>
      {children}
    </button>
  );
};

export default ClipboardCopy;
