import { Chain } from 'types/web3';

export const BSCTestnet: Chain = {
  chainId: 97,
  chainName: 'Smart Chain - Testnet',
  isTestChain: true,
  isLocalChain: false,
  multicallAddress: '0xae11C5B5f29A6a25e955F0CB8ddCc416f522AF5C',
  chainIdString: `0x${(97).toString(16)}`,
  nativeCurrency: {
    name: 'BNB',
    symbol: 'BNB',
    decimals: 18,
  },
  bscApiEndpoint: 'https://api-testnet.bscscan.com/api',
  rpcUrl: 'https://data-seed-prebsc-1-s1.binance.org:8545',
  blockExplorerUrls: ['https://testnet.bscscan.com'],
};

export const BSC: Chain = {
  chainId: 56,
  chainName: 'Smart Chain',
  isTestChain: false,
  isLocalChain: false,
  multicallAddress: '0x41263cba59eb80dc200f3e2544eda4ed6a90e76c',
  chainIdString: `0x${(56).toString(16)}`,
  nativeCurrency: {
    name: 'BNB',
    symbol: 'BNB',
    decimals: 18,
  },
  bscApiEndpoint: 'https://api.bscscan.com/api',
  rpcUrl: 'https://bsc-dataseed.binance.org',
  blockExplorerUrls: ['https://bscscan.com'],
};

const CHAINS = {
  [BSCTestnet.chainId]: {
    ...BSCTestnet,
  },
  [BSC.chainId]: {
    ...BSC,
  },
};

export default CHAINS;
