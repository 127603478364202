import React, { useEffect, useMemo, useState } from 'react';

import { Contract } from 'ethers';
import { observer } from 'mobx-react';
import { useWeb3React } from '@web3-react/core';
import { parseUnits } from '@ethersproject/units';
import { NumericFormat } from 'react-number-format';
import { Controller, useForm } from 'react-hook-form';

import { useStore } from 'store';
import { CONTRACT_ADDRESS } from 'const/env';
import CONTRACT_ABI from 'config/contractAbi';

import { Modal } from 'components/feedback';
import { ReactComponent as TargetIcon } from 'static/icons/target.svg';
import { ReactComponent as QuestionMarkIcon } from 'static/icons/question-mark.svg';

import styles from './ParticipateModal.module.scss';

interface ParticipateFormValues {
  amount: number | string;
}

const ParticipateModal: React.FC = () => {
  const { layoutStore } = useStore();
  const { provider } = useWeb3React();
  const [isParticipateLoading, setIsParticipateLoading] = useState(false);

  const contract = useMemo(() => new Contract(CONTRACT_ADDRESS, CONTRACT_ABI, provider?.getSigner()), [provider]);

  const participateForm = useForm<ParticipateFormValues>({
    defaultValues: {
      amount: '',
    },
  });

  const handleParticipateSubmit = async (values: ParticipateFormValues) => {
    try {
      setIsParticipateLoading(true);
      const searchParams = new URLSearchParams(window.location.search);
      const refAddress = searchParams.get('ref') ?? '0x0000000000000000000000000000000000000000';
      await contract.functions?.bid(refAddress, { value: parseUnits(values.amount as string, 18), gasLimit: 700000 });
      layoutStore.closeParticipateModal();
    } catch (error) {
      console.log(error);
      if ((error as { data: { code: number } })?.data?.code === -32000) {
        layoutStore.openFeedbackModal('Insufficient funds');
      }
    } finally {
      setIsParticipateLoading(false);
    }
  };

  useEffect(() => {
    participateForm.reset({ amount: '' });
  }, [layoutStore.isParticipateModalOpen, participateForm]);

  return (
    <Modal
      className={styles.ParticipateModal}
      isOpen={layoutStore.isParticipateModalOpen}
      onClose={layoutStore.closeParticipateModal}
    >
      <h2 className={styles.title}>Participate in the lottery</h2>
      <p className={styles.paragraph}>To participate, deposit any amount</p>

      <form className={styles.form} onSubmit={participateForm.handleSubmit(handleParticipateSubmit)}>
        <Controller
          name="amount"
          control={participateForm.control}
          render={({ field: { ref: _ref, ...field } }) => (
            <NumericFormat className={styles.input} {...field} allowNegative={false} placeholder="min: 0.01" />
          )}
        />

        <div className={styles.controls}>
          <button className={styles.submitButton} type="submit" disabled={isParticipateLoading}>
            <TargetIcon className={styles.icon} />
            Place a bet
          </button>

          <button
            className={styles.helpButton}
            type="button"
            onClick={layoutStore.openHelpModal}
            disabled={isParticipateLoading}
          >
            <QuestionMarkIcon className={styles.icon} />
            Lottery Rules
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default observer(ParticipateModal);
