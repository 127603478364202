import CHAINS from 'const/chains';
import { REACT_APP_CHAIN_ID } from 'const/env';

export const chain = CHAINS[parseInt(REACT_APP_CHAIN_ID)];

export const chainParams = {
  chainId: chain.chainId,
  chainName: chain.chainName,
  nativeCurrency: chain.nativeCurrency,
  rpcUrls: [chain.rpcUrl],
  blockExplorerUrls: chain.blockExplorerUrls,
};
