import React, { useCallback, useEffect, useState } from 'react';

import cx from 'classnames';
import { useStore } from 'store';
import { observer } from 'mobx-react';
import { useWeb3React } from '@web3-react/core';
import { NoMetaMaskError } from '@web3-react/metamask';

import { chain, chainParams } from 'config/chain';
import { metaMask } from 'config/connectors/metaMask';
import { walletConnect } from 'config/connectors/walletConnect';

import { Modal, Spinner } from 'components/feedback';

import { ReactComponent as CloseIcon } from 'static/icons/close.svg';
import { ReactComponent as CheckIcon } from 'static/icons/check.svg';
import { ReactComponent as MetamaskIcon } from 'static/icons/connectors/metamask.svg';
import { ReactComponent as WalletConnectIcon } from 'static/icons/connectors/wallet-connect.svg';

import styles from './ConnectModal.module.scss';

const ConnectModal: React.FC = () => {
  const { layoutStore } = useStore();
  const { isActivating, isActive, chainId, connector } = useWeb3React();

  const [error, setError] = useState<string | null>(null);

  const activateMetaMask = () => {
    localStorage.removeItem('walletconnect');
    metaMask.activate(chainParams).catch((responseError) => {
      if (responseError instanceof NoMetaMaskError) {
        window.open(`https://metamask.app.link/dapp/${window.location.host}/`, '_blank');
      } else if (responseError?.code === 4001) {
        handleClose();
      }
    });
  };

  const actiavteWalletConnect = () => {
    walletConnect.activate(chain.chainId).catch((responseError) => {
      if (responseError?.message === 'User closed modal') {
        handleClose();
      } else {
        setError(responseError?.message);
      }
    });
  };

  const handleClose = useCallback(() => {
    setError(null);
    layoutStore.closeConnectModal();
  }, [layoutStore]);

  useEffect(() => {
    if (isActive && chainId === chain.chainId) {
      const timeout = setTimeout(() => handleClose(), 2000);

      return () => {
        clearTimeout(timeout);
      };
    } else if (isActive) {
      setError('Network is not supported. Please try to change it on your wallet.');
      connector.deactivate?.();
    }
  }, [isActive, handleClose, chainId, connector]);

  return (
    <Modal
      isOpen={layoutStore.isConnectModalOpen}
      onClose={!isActive && !isActivating ? handleClose : undefined}
      className={styles.ConnectModal}
    >
      {!error && !isActivating && !isActive && (
        <>
          <span className={styles.label}>Connect your wallet</span>
          <div className={styles.connectors}>
            <button className={styles.connector} onClick={actiavteWalletConnect}>
              <WalletConnectIcon className={styles.icon} />
            </button>
            <div className={styles.delimeter} />
            <button className={styles.connector} onClick={activateMetaMask}>
              <MetamaskIcon className={styles.icon} />
            </button>
          </div>
        </>
      )}

      {error && (
        <div className={cx(styles.message, styles.error)}>
          <CloseIcon className={styles.icon} />
          <span className={styles.label}>{error}</span>
        </div>
      )}

      {isActivating && (
        <div className={cx(styles.message, styles.loading)}>
          <Spinner className={styles.icon} />
          <span className={styles.label}>Connection</span>
        </div>
      )}

      {isActive && !error && (
        <div className={cx(styles.message, styles.success)}>
          <CheckIcon className={styles.icon} />
          <span className={styles.label}>Connection success</span>
        </div>
      )}
    </Modal>
  );
};

export default observer(ConnectModal);
