import { makeAutoObservable } from 'mobx';

import RootStore from 'store';

export default class LayoutStore {
  rootStore: RootStore;

  isHelpModalOpen: boolean;

  isConnectModalOpen: boolean;

  feedbackModalMessage: string | null;

  isParticipateModalOpen: boolean;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {}, { deep: false, autoBind: true, name: 'layoutStore' });
    this.rootStore = rootStore;
    this.isHelpModalOpen = false;
    this.isConnectModalOpen = false;
    this.isParticipateModalOpen = false;
    this.feedbackModalMessage = null;
  }

  openHelpModal() {
    this.isHelpModalOpen = true;
  }

  closeHelpModal() {
    this.isHelpModalOpen = false;
  }

  openConnectModal() {
    this.isConnectModalOpen = true;
  }

  closeConnectModal() {
    this.isConnectModalOpen = false;
  }

  openFeedbackModal(message: string) {
    this.feedbackModalMessage = message;
  }

  closeFeedbackModal() {
    this.feedbackModalMessage = null;
  }

  openParticipateModal() {
    this.isParticipateModalOpen = true;
  }

  closeParticipateModal() {
    this.isParticipateModalOpen = false;
  }
}
