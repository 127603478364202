import React from 'react';

import { observer } from 'mobx-react';

import { useStore } from 'store';

import { Modal } from 'components/feedback';

import styles from './FeedbackModal.module.scss';

const FeedbackModal: React.FC = () => {
  const { layoutStore } = useStore();

  return (
    <Modal
      className={styles.FeedbackModal}
      isOpen={!!layoutStore.feedbackModalMessage}
      onClose={layoutStore.closeFeedbackModal}
    >
      <p className={styles.message}>{layoutStore.feedbackModalMessage}</p>
    </Modal>
  );
};

export default observer(FeedbackModal);
