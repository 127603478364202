import React, { useEffect } from 'react';

import { metaMask } from 'config/connectors/metaMask';
import { walletConnect } from 'config/connectors/walletConnect';

import { ConnectModal, FeedbackModal, HelpModal, ParticipateModal } from 'components/modals';

import MainPage from 'pages/MainPage';

const App: React.FC = () => {
  useEffect(() => {
    if (localStorage.getItem('walletconnect')) {
      walletConnect.connectEagerly();
    } else {
      metaMask.connectEagerly();
    }
  });

  return (
    <>
      <MainPage />
      <ConnectModal />
      <HelpModal />
      <FeedbackModal />
      <ParticipateModal />
    </>
  );
};

export default App;
