import { initializeConnector } from '@web3-react/core';
import { WalletConnect } from '@web3-react/walletconnect';

import { BSC, BSCTestnet } from 'const/chains';

export const [walletConnect, hooks, store] = initializeConnector<WalletConnect>(
  (actions) =>
    new WalletConnect({
      actions,
      options: {
        bridge: 'https://bridge.walletconnect.org',
        rpc: {
          [BSC.chainId]: [BSC.rpcUrl],
          [BSCTestnet.chainId]: [BSCTestnet.rpcUrl],
        },
      },
    })
);
