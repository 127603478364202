import React from 'react';

import LayoutStore from './layoutStore';

interface IStoreProviderProps {
  children: React.ReactNode;
  store: RootStore;
}

export default class RootStore {
  layoutStore: LayoutStore;

  constructor() {
    this.layoutStore = new LayoutStore(this);
  }
}

export const StoreContext = React.createContext<RootStore>({} as RootStore);

export function StoreProvider({ store, children }: IStoreProviderProps) {
  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
}

export const useStore = () => React.useContext(StoreContext);
