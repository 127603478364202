import React from 'react';

import { observer } from 'mobx-react';

import { useStore } from 'store';

import { Modal } from 'components/feedback';

import styles from './HelpModal.module.scss';

const HelpModal: React.FC = () => {
  const { layoutStore } = useStore();

  return (
    <Modal className={styles.HelpModal} isOpen={layoutStore.isHelpModalOpen} onClose={layoutStore.closeHelpModal}>
      <h2 className={styles.title}>Lottery Participation Guidelines</h2>
      <p className={styles.paragraph}>
        Participants are required to send 0.01 BNB to enter the lottery. Upon the conclusion of the countdown, the
        entire pool of collected BNB is automatically awarded to the participant who executed the final transaction.
      </p>

      <h3 className={styles.title}>Who is the winner?</h3>
      <p className={styles.paragraph}>
        User who are placed the highest bet will share the prize with user who are placed the last bet (any amount). The
        prize pool will be split 50/50 between the two participants.
      </p>

      <h3 className={styles.title}>Entry Bet for the Lottery</h3>
      <p className={styles.paragraph}>
        By selecting the 'participate' option, an entry fee of <b>0.01 BNB</b> will be deducted from your account,
        instantly enrolling you in the lottery.
      </p>

      <h3 className={styles.title}>Timer Mechanism Explained</h3>
      <p className={styles.paragraph}>
        The lottery's countdown timer is halved for every 100 transactions processed, accelerating the end of the
        lottery period.
      </p>
    </Modal>
  );
};

export default observer(HelpModal);
