import React from 'react';

import ReactDOM from 'react-dom/client';
import { MetaMask } from '@web3-react/metamask';
import { WalletConnect } from '@web3-react/walletconnect';
import { Web3ReactProvider, Web3ReactHooks } from '@web3-react/core';

import App from 'App';
import Store, { StoreProvider } from 'store';
import reportWebVitals from 'reportWebVitals';
import { metaMask, hooks as metaMaskHooks } from 'config/connectors/metaMask';
import { walletConnect, hooks as walletConnectHooks } from 'config/connectors/walletConnect';

import 'config/numbro';
import 'static/styles/index.scss';

const store = new Store();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const connectors: [MetaMask | WalletConnect, Web3ReactHooks][] = [
  [metaMask, metaMaskHooks],
  [walletConnect, walletConnectHooks],
];

root.render(
  <React.StrictMode>
    <Web3ReactProvider connectors={connectors}>
      <StoreProvider store={store}>
        <App />
      </StoreProvider>
    </Web3ReactProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
