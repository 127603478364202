import React, { useCallback, useEffect, useMemo, useState } from 'react';

import axios from 'axios';
import cx from 'classnames';
import moment from 'moment';
import numbro from 'numbro';
import { BigNumber } from 'ethers';
import { observer } from 'mobx-react';
import { useWeb3React } from '@web3-react/core';
import { formatEther, parseUnits } from '@ethersproject/units';
import { Contract } from '@ethersproject/contracts';

import { useStore } from 'store';
import { chain } from 'config/chain';
import CONTRACT_ABI from 'config/contractAbi';
import { CONTRACT_ADDRESS, REACT_APP_BSC_API_KEY } from 'const/env';

import handImg from 'static/images/hand.png';
import lastBetImg from 'static/images/last-bet-nft.png';
import biggestBetImg from 'static/images/biggest-bet-nft.png';
import timerBgMobile from 'static/images/timer-bg-mobile.png';
import { ReactComponent as Logo } from 'static/images/logo.svg';
import { ReactComponent as BnbIcon } from 'static/icons/bnb.svg';
import { ReactComponent as CopyIcon } from 'static/icons/copy.svg';
import transparentHandImg from 'static/images/hand-transparent.png';
import { ReactComponent as PrizeIcon } from 'static/icons/prize.svg';
import { ReactComponent as RocketIcon } from 'static/icons/rocket.svg';
import { ReactComponent as WalletIcon } from 'static/icons/wallet.svg';
import { ReactComponent as LogoutIcon } from 'static/icons/logout.svg';
import { ReactComponent as SecureIcon } from 'static/icons/secure.svg';
import { ReactComponent as LogoShort } from 'static/images/logo-short.svg';
import { ReactComponent as QuestionMarkIcon } from 'static/icons/question-mark.svg';
import { ReactComponent as MetamaskIcon } from 'static/icons/connectors/metamask.svg';

import styles from './MainPage.module.scss';
import { Modal } from 'components/feedback';
import ClipboardCopy from 'components/ui/ClipboardCopy';
import { formatAmount } from 'utils/format';

type TransactionTableItem = {
  time: string;
  account: string;
  amount: string;
};

// const SECONDS_IN_HOUR = 3600;

const calculateDuration = (eventTime: number) => {
  return moment.duration(Math.max(eventTime - moment().unix(), 0), 'seconds');
};

const MainPage: React.FC = () => {
  const { layoutStore } = useStore();
  const { account, isActive, connector, provider } = useWeb3React();

  const [userStatus, setUserStatus] = useState(false);
  const [lotteryEndTime, setLotteryEndTime] = useState(moment().unix());
  const [isParticipateLoading, setIsParticipateLoading] = useState(false);
  const [contractBalance, setContractBalance] = useState(BigNumber.from(0));
  const [duration, setDuration] = useState(calculateDuration(lotteryEndTime));
  const [transactions, setTransactions] = useState<TransactionTableItem[]>([]);
  const [isSecureConnectionHintVisible, setIsSecureConnectionHintVisible] = useState(false);

  const [isClaimLoading, setIsClaimLoading] = useState(false);

  const accountShort = useMemo(() => (account ? `****${account?.slice(-4)}` : 'Loading...'), [account]);
  const contract = useMemo(() => new Contract(CONTRACT_ADDRESS, CONTRACT_ABI, provider?.getSigner()), [provider]);

  const getContractBalance = useCallback(() => {
    if (isActive && provider) {
      provider
        .getBalance(CONTRACT_ADDRESS)
        .then((response) => {
          setContractBalance(response);
        })
        .catch(() => {
          setContractBalance(BigNumber.from(0));
        });
    } else {
      axios
        .get(
          `${chain.bscApiEndpoint}/?module=account&action=balance&address=${CONTRACT_ADDRESS}&apiKey=${REACT_APP_BSC_API_KEY}`
        )
        .then(({ data }) => {
          setContractBalance(BigNumber.from(data?.result as string));
        })
        .catch();
    }
  }, [isActive, provider]);

  const getTransactionsList = useCallback(
    () =>
      axios
        .get(
          `${chain.bscApiEndpoint}/?module=account&action=txlist&sort=desc&page=1&offset=4&address=${CONTRACT_ADDRESS}&apiKey=${REACT_APP_BSC_API_KEY}`
        )
        .then(({ data }) => {
          console.log(data);
          setTransactions(
            data?.result?.map((transaction: { timeStamp: string; from: string; value: string }, index: number) => ({
              time: moment.unix(parseInt(transaction.timeStamp)).fromNow(),
              account: `****${transaction.from?.slice(-4)}`,
              amount: formatEther(transaction.value),
            })) || []
          );
        })
        .catch(),
    []
  );

  const calculateLotteryEndTime = useCallback(async () => {
    try {
      const [lastBidTime] = await contract.functions?.lastBidTime();
      setLotteryEndTime(lastBidTime.toNumber());
    } catch (error) {
      console.log(error);
    }
  }, [contract]);

  const getUserStatus = useCallback(async () => {
    try {
      const [newUserStatus] = await contract.functions?.getUserStatus(account);
      setUserStatus(newUserStatus);
    } catch (error) {
      console.log(error);
    }
  }, [contract, account]);

  const claim = useCallback(async () => {
    try {
      setIsClaimLoading(true);
      await contract.functions?.getReward();
    } catch (error) {
      console.log(error);
    } finally {
      setIsClaimLoading(false);
    }
  }, [contract]);

  const handleParticipateSubmit = async () => {
    try {
      setIsParticipateLoading(true);
      const searchParams = new URLSearchParams(window.location.search);
      const refAddress = searchParams.get('ref') ?? '0x0000000000000000000000000000000000000000';
      await contract.functions?.bid(refAddress, { value: parseUnits('0.01' as string, 18) });
      layoutStore.closeParticipateModal();
    } catch (error) {
      console.log(error);
      if ((error as { data: { code: number } })?.data?.code === -32000) {
        layoutStore.openFeedbackModal('Insufficient funds');
      }
    } finally {
      setIsParticipateLoading(false);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      getContractBalance();
      getTransactionsList();
      if (isActive) {
        calculateLotteryEndTime();
        getUserStatus();
      }
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [isActive, getContractBalance, getTransactionsList, calculateLotteryEndTime, getUserStatus]);

  useEffect(() => {
    const interval = setInterval(() => setDuration(calculateDuration(lotteryEndTime)), 1000);

    return () => {
      clearInterval(interval);
    };
  }, [lotteryEndTime]);

  return (
    <>
      <div className={styles.MainPage}>
        <img className={styles.lastBetImg} src={lastBetImg} alt="" />
        <img className={styles.biggestBetImg} src={biggestBetImg} alt="" />

        <header className={styles.header}>
          <Logo className={styles.logo} />
          <LogoShort className={styles.logoShort} />

          <div className={cx(styles.controls, styles.connected)}>
            {isActive ? (
              <ClipboardCopy className={cx(styles.refLink)} value={`${window.location.origin}?ref=${account}`}>
                <MetamaskIcon className={styles.icon} />
                <span>
                  {window.location.origin}?ref={account}
                </span>
                <CopyIcon className={styles.copyIcon} />
              </ClipboardCopy>
            ) : (
              <button className={styles.helpButton} onClick={layoutStore.openHelpModal}>
                <QuestionMarkIcon className={styles.icon} />
                <span>How it works?</span>
              </button>
            )}

            {/* {!isActive && (
              <button className={styles.connectButton} onClick={layoutStore.openConnectModal}>
                <WalletIcon className={styles.icon} />
                Connect wallet
              </button>
            )} */}

            {isActive && (
              <button
                className={styles.logoutButton}
                onClick={() => {
                  if (connector.deactivate) {
                    connector.deactivate();
                  } else {
                    connector.resetState();
                  }
                }}
              >
                <BnbIcon className={styles.icon} />
                {accountShort}
                <LogoutIcon className={styles.icon} />
              </button>
            )}
          </div>
        </header>

        <div className={styles.content}>
          <div className={styles.timer}>
            <h1 className={styles.title}>
              <PrizeIcon className={styles.icon} />
              The 2 winners will share the prize pool!
            </h1>

            <img className={styles.timerBgMobile} src={timerBgMobile} alt="" />

            <div className={styles.digits}>
              <div className={styles.digitWrapper}>
                <div className={cx(styles.digit, { [styles.over]: !duration.hours() })}>{duration.hours()}</div>
                <span className={styles.label}>hour</span>
              </div>

              <span className={styles.doubleDot}>:</span>

              <div className={styles.digitWrapper}>
                <div className={cx(styles.digit, { [styles.over]: !duration.hours() && !duration.minutes() })}>
                  {duration.minutes()}
                </div>
                <span className={styles.label}>min</span>
              </div>

              <span className={styles.doubleDot}>:</span>

              <div className={styles.digitWrapper}>
                <div
                  className={cx(styles.digit, {
                    [styles.over]: !duration.hours() && !duration.minutes() && !duration.seconds(),
                  })}
                >
                  {duration.seconds()}
                </div>
                <span className={styles.label}>sec</span>
              </div>
            </div>
          </div>

          <div className={styles.contract}>
            <span className={styles.label}>Prize pool</span>

            <div className={styles.value}>
              <img className={styles.transparentHand} src={transparentHandImg} alt="" />
              <BnbIcon className={styles.icon} />
              {numbro(formatEther(contractBalance)).format()}
            </div>
            <p className={styles.hint}>
              Make bet <span>0.01 BNB</span>
            </p>

            <div className={styles.controls}>
              <img className={styles.hand} src={handImg} alt="" />
              <button className={styles.helpButton} onClick={layoutStore.openHelpModal}>
                <QuestionMarkIcon className={styles.icon} />
              </button>

              {!isActive && (
                <button className={styles.connectButton} onClick={layoutStore.openConnectModal}>
                  <WalletIcon className={styles.icon} />
                  Connect wallet
                </button>
              )}

              {isActive && !userStatus && (
                <button
                  className={styles.participateButton}
                  onClick={handleParticipateSubmit}
                  disabled={isParticipateLoading}
                >
                  <RocketIcon className={styles.icon} />
                  Place a bet
                </button>
              )}

              {isActive && userStatus && (
                <button className={styles.claimButton} onClick={claim} disabled={isClaimLoading}>
                  <RocketIcon className={styles.icon} />
                  Claim Your Reward
                </button>
              )}

              <button className={cx(styles.secureButton, styles.desktop)}>
                <SecureIcon className={styles.icon} />
                <span>Secure connection</span>
              </button>

              <button
                className={cx(styles.secureButton, styles.mobile)}
                onClick={() => setIsSecureConnectionHintVisible(true)}
              >
                <SecureIcon className={styles.icon} />
                <span>Secure connection</span>
              </button>
            </div>
          </div>

          {!!transactions.length && (
            <div className={styles.tableWrapper}>
              <span className={styles.label}>Latest transactions</span>

              <div className={styles.table}>
                {transactions.map((transaction, index) => (
                  <div key={index} className={styles.row}>
                    <div className={styles.address}>
                      <span>0x</span>
                      {transaction.account}
                    </div>
                    <div className={styles.time}>{transaction.time}</div>
                    <div className={styles.amount}>
                      <span>+{formatAmount(transaction.amount)}</span> <BnbIcon className={styles.icon} />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>

      <Modal
        className={styles.securetConnectionModal}
        isOpen={isSecureConnectionHintVisible}
        onClose={() => setIsSecureConnectionHintVisible(false)}
      >
        <span className={styles.message}>
          <SecureIcon className={styles.icon} />
          Secure connection
        </span>
      </Modal>
    </>
  );
};

export default observer(MainPage);
