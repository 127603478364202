export const formatAmount = (amount: string) => {
  const [decimal, float] = amount.split('.');
  const floatLength = float.length;
  if (floatLength > 7) {
    return (
      <>
        {decimal}.{float.slice(0, 3)}...<sub>{floatLength - 6}</sub>
        {float.slice(floatLength - 3, floatLength)}
      </>
    );
  } else {
    return [decimal, float].join('.');
  }
};
