import React from 'react';

import cx from 'classnames';
import ReactDOM from 'react-dom';
import { AnimatePresence, motion } from 'framer-motion';

import { useScrollLock } from 'utils/hooks';

import { ReactComponent as CloseIcon } from 'static/icons/close.svg';

import styles from './Modal.module.scss';

interface ModalProps {
  children: React.ReactNode;
  isOpen: boolean;
  className?: string;
  onClose?: () => void;
  lockScroll?: boolean;
}

const Modal: React.FC<ModalProps> = ({ children, isOpen, className, onClose, lockScroll = true }) => {
  useScrollLock(lockScroll && isOpen);

  return ReactDOM.createPortal(
    <AnimatePresence>
      {isOpen && (
        <motion.div
          key="modalOverlay"
          className={cx(styles.overlay, { noClose: !onClose })}
          variants={{
            hide: { opacity: 0 },
            show: { opacity: 1 },
          }}
          initial="hide"
          animate="show"
          exit="hide"
        >
          <motion.div
            key="modalContent"
            className={cx(styles.card, className)}
            variants={{
              hide: { transform: 'translateY(20px)' },
              show: { transform: 'translateY(0px)' },
            }}
          >
            {children}

            {onClose && (
              <button className={styles.close} type="button" onClick={onClose}>
                <CloseIcon className={styles.icon} />
              </button>
            )}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>,
    document.body
  );
};

export default Modal;
