import numbro from 'numbro';

const locale = require('numbro/languages/ru-RU');
numbro.registerLanguage({
  ...locale,
  delimiters: {
    thousands: ',',
    decimal: '.',
  },
});
numbro.setLanguage('ru-RU');

numbro.setDefaults({
  mantissa: 2,
  trimMantissa: true,
  thousandSeparated: true,
  roundingFunction: (number) =>
    Math.abs(number) < 1 ? Math.ceil(Math.abs(number)) * Math.sign(number) : Math.round(number),
});
